import { v4 as uuid4 } from 'uuid';
import Cookie from './Cookie';
import {StatCookieData, StatCookieType} from "../model/StatCookieType";
import AmpUserCookie from "./AmpUserCookie";

const cookieName = 'ca_stat';
const mtl = 6;
const thresholdAddVisit = (30 * 60 * 1000);

class StatCookie extends Cookie implements StatCookieType {
    public data: string;
    public ampUserCookie;

    constructor() {
        super(cookieName);
        this.generateUserId();
        this.generateVisitData();
        this.ampUserCookie = new AmpUserCookie();
        this.ampUserCookie.setData(this.getUserId(), 0);
    }

    resolveData(): StatCookieData {
        if (this.data === '') {
            this.data = '{}';
        }

        return JSON.parse(this.data);
    }

    getDate(): null|Date {
        const info = this.resolveData();

        if (!info.time) {
            return null;
        }

        return new Date(info.time);
    }

    setDate(date: Date): void {
        const info = this.resolveData();
        info.time = date.getTime();
        this.data = JSON.stringify(info);
    }

    getUserId(): string {
        const info = this.resolveData();

        if (typeof (info.userId) === 'undefined') {
            return '';
        }

        return info.userId;
    }

    getAmpUserId(): string {
        return this.ampUserCookie.getData();
    }

    generateUserId(): void {
        if (this.getUserId() !== '') {
            return;
        }

        const info = this.resolveData();
        info.userId = uuid4();
        this.data = JSON.stringify(info);
    }

    generateVisitData = (): void => {
        const data = this.resolveData();

        if (!data.visit) {
            Object.assign(data, {
                visit: {
                    id: uuid4(),
                },
            });
        }

        if (data.visit.time) {
            const oldTime = new Date(data.visit.time);
            const currentTime = new Date();

            if ((currentTime.getTime() - oldTime.getTime()) >= thresholdAddVisit) {
                Object.assign(data, {
                    visit: {
                        id: uuid4(),
                    },
                });
            }
        }

        Object.assign(data.visit, {
            time: (new Date()).getTime(),
        });

        this.data = JSON.stringify(data);
    };

    persist(): void {
        this.setDate(new Date());
        this.store(mtl);
    }
}

export default StatCookie;
