import Cookie from './Cookie';
import {ReadingTimeData} from "../model/ReadingTime";

class ReadingTimeCookie extends Cookie {
    constructor() {
        super('ca_reading_time');
    }

    public fetchData(): ReadingTimeData {
        if (this.data === '') {
            this.data = '{}';
        }

        return JSON.parse(this.data);
    }

    public persist(id: string, ms: number): void {
        this.data = JSON.stringify({
            pageView: id,
            time: ms,
        })

        this.store(0);
    }
}

export default ReadingTimeCookie;